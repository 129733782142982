<template>
  <TN />
</template>


<script>
import TN from '@/components/tn/import.vue'
export default {
  components: {
    TN,
  },
}
</script>

